<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('core.organization.import')" class="user_import">
    <CCol col="6" xl="6" lg="12" md="12" sm="12">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          {{ $t('users.Import_users') }} ({{ $t('users.Maximum_amount') }} {{licenseData.allowed_company_users}} {{ $t('common.users') }})      
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol cols="12" lg="12" class="pt-0">
              <p v-html="$t('users.Import_users_explanation')" class="mb-2"></p>
              <howToLink v-if="environmentTag === 'harryhr'" :subject="$t('howto.importing_users_into') + ' Harry HR'" url="https://harryhr.com/how-to/user-management/import-employees-into-harry-hr/"/>
              <howToLink v-if="environmentTag === 'employalty'" :subject="$t('howto.importing_users_into') + ' Employalty'" url="https://employalty.app/support/"/>
            </CCol>
          </CRow> 
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <vue-csv-import id="vueCSVInput" v-if="fieldsLoaded" ref="vueCSVImport" v-model="importData" input-class="csv_select" table-class="table csv_mapping" :map-fields="importFields" :auto-match-fields="true" headers="true">                                
                <template slot="thead">
                  <tr>
                    <th><span>{{ $t('users.Necessary_data') }}</span></th>
                    <th><span>{{ $t('users.Column_in_csv_file') }}</span></th>
                  </tr>
                </template>
                <template slot="next" slot-scope="{load}">
                  <CButton v-show="!hideMappingButton" color="primary" @click.prevent="load">
                    <span><i class="fas fa-file-upload mr-1"/>{{$t('users.Map_csv_columns')}}</span>
                  </CButton>
                </template>
              </vue-csv-import>

              <CButton color="primary" @click="submitImportData();" :disabled="submitButtonDisabled">
                <span><i class="fas fa-file-arrow-up mr-1"/>{{$t('users.Import_users')}}</span>
              </CButton>              
            </CCol>
          </CRow>
        </CCardBody>        
      </CCard>

      <b-modal class="import" :can-cancel="[]" :active.sync="maxUserModal" :width="960" scroll="keep">
        <CCard class="mb-0">
          <CCardHeader class="pb-0">
            {{$t('users.Import_not_allowed')}}
          </CCardHeader>
          <b-notification :closable="false">
            <CCardBody>
              <CRow>
                <CCol cols="12" lg="12" class="pt-0">
                  <p class="mb-0">{{ $t('users.Your_csv_contains') }} {{importData.length}} {{ $t('common.users') }}. {{ $t('users.This_exceeds_maximum') }} ({{licenseData.allowed_company_users}}). {{ $t('users.change_csv_or_update_license') }}</p>
                </CCol>
                <CCol cols="12" lg="12" class="pt-0 pb-0">
                  <CButton v-if="checkPermission('core.license')" color="primary" @click="openLicenseSettings()"><i class="fas fa-key mr-1"/>{{$t('common.View_current_license')}}</CButton>
                  <CButton color="secondary" @click="maxUserModal = false"><i class="fas fa-times mr-1"/>{{ $t('close') }}</CButton>
                </CCol>                                        
              </CRow>    
            </CCardBody>
          </b-notification>
        </CCard>
      </b-modal>

      <b-modal class="import" :can-cancel="[]" :active.sync="confirmModal" :width="960" scroll="keep">
        <CCard class="mb-0">
          <CCardHeader class="pb-0">
            {{$t('users.Confirm_user_import')}}
          </CCardHeader>
          <b-notification :closable="false">
            <CCardBody>
              <CRow>
                <CCol cols="12" lg="12" class="pt-0 pb-0">
                  <p class="mb-0">{{ $t('users.Sure_to_import') }} {{importData.length}} {{importData.length != 1 ? $t('common.users') : $t('common.user')}} {{ $t('users.will_be_imported') }}.</p>                        
                </CCol>            
              </CRow>    
            </CCardBody>
            <CCardFooter>
              <CButton color="primary" @click="importUsers()"><i class="fas fa-check mr-1"/>{{ $t('users.Confirm_import') }}</CButton>
              <CButton color="secondary" @click="confirmModal = false"><i class="fas fa-times mr-1"/>{{ $t('cancel') }}</CButton>
            </CCardFooter>
            <b-loading :is-full-page="isFullPage" :active.sync="isLoading" :can-cancel="true">
              <b-icon pack="fas" icon="sync-alt" custom-class="fa-spin"></b-icon>
              <span style="margin-left:10px">{{ $t('users.Importing_users') }}</span>
            </b-loading>
          </b-notification>
        </CCard>
      </b-modal>

      <b-modal class="import" :can-cancel="[]" :active.sync="validationModal" :width="960" scroll="keep">
        <CCard class="mb-0">
          <CCardHeader class="pb-0">
            {{$t('user_import.Validating_your_csv_file')}}
          </CCardHeader>
          <b-notification :closable="false">
            <CCardBody>
              <CRow v-if="!isValidating">
                <CCol cols="12" lg="12" class="pt-0 pb-0">
                  <div v-if="csvErrorMessages.length > 0">
                    <span>{{$t('user_import.Your_csv_file_has_been_validated')}} {{$t('user_import.Your_csv_file_contains_errors')}}:</span>
                    <div v-for="(errorMessage, index) in csvErrorMessages" :key="index" class="error_messages mt-2 mb-2">
                      <span>{{errorMessage}}</span>
                    </div>
                    <span>{{$t('user_import.Please_fix_the_errors')}}</span>
                  </div>
                  <div v-else>
                    <span>{{$t('user_import.Your_csv_file_has_been_validated')}} {{$t('user_import.Your_csv_file_contains_no_errors')}}</span>
                  </div>
                </CCol>            
              </CRow>    
            </CCardBody>
            <CCardFooter>            
              <CButton v-if="csvErrorMessages.length === 0" color="primary" @click="loadImportData()"><i class="fas fa-file-upload mr-1"/>{{ $t('users.Map_csv_columns') }}</CButton>
              <CButton color="secondary" @click="clearImportData();"><i class="fas fa-times mr-1"/>{{$t('close')}}</CButton>
            </CCardFooter>
            <b-loading :is-full-page="isFullPage" :active.sync="isValidating" :can-cancel="true">
              <b-icon pack="fas" icon="sync-alt" custom-class="fa-spin"></b-icon>
              <span style="margin-left:10px">{{ $t('user_import.Validating_csv_file') }}</span>
            </b-loading>
          </b-notification>
        </CCard>
      </b-modal>      
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import { VueCsvImport } from 'vue-csv-import';
import CSVFileValidator from 'csv-file-validator'

import noPermission from '@/components/common/noPermission.vue';
import howToLink from '@/components/common/howToLink.vue';

export default {
  name: 'ImportUsers',
  components: {
    VueCsvImport,
    CSVFileValidator,
    noPermission,
    howToLink
  },
  data: () => {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,
      environmentTag: null,   
      importData: [],
      licenseData: {
        allowed_company_users: 0
      },
      companyData: {
        nr_of_departments: 0,
        nr_of_teams: 0
      },
      confirmModal: false,
      maxUserModal: false,
      validationModal: false,
      isLoading: false,
      isValidating: false,
      isFullPage: false,
      importFields: {},
      fieldsLoaded: false,
      hideMappingButton: true,      
      submitButtonDisabled: true,        
      csvEmployeeNumbers: [],
      csvErrorMessages: [],
      csvConfig: {
        headers: [],
        isHeaderNameOptional: false,
        isColumnIndexAlphabetic: false
      }
    }
  },
  methods: {
    getCompanyData() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/company')
      .then(res => {
        this.companyData = res.data.data;
        // Set the import fields
        this.setImportFields();
        // Set the CSV config headers
        this.setCsvConfigHeaders();
      })
      .catch(err => {
        console.error(err); 
      });
    },
    importUsers() {
      let params = {};
      params.users = this.importData;
      this.isLoading = true;

      axios.post(process.env.VUE_APP_API_URL + '/v1/core/users/import', params)
      .then(res => {
        // Hide the loader
        this.isLoading = false;
        // Hide the confirmation modal
        this.confirmModal = false;
        // Show succes toast
        this.$buefy.toast.open({ message: this.$t('users.Users_imported'), type: 'is-success', duration: 4000 });
        // Navigate to user overview
        this.$router.push({path: '/core/organization'});
      })
      .catch(err => {
        // Hide the loader
        this.isLoading = false;
        // Hide the confirmation modal
        this.confirmModal = false;
        // Show correct error message according to error
        if(err.response.data.error === 'License upgrade needed') {          
          this.$buefy.toast.open({ message: this.$t('users.Upgrade_license_for_import'), type: 'is-danger', duration: 2000 });
        } else {
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        }
      })
    },
    setImportFields() {
      // Set the importFields
      this.importFields = {
        name: this.$t('user_import.Employee') + '*',
        email: this.$t('user_import.Email') + '*',
        department_name: this.$t('user_import.Department'),
        team_name: this.$t('user_import.Team'),
        dob: this.$t('user_import.Date_of_birth'),
        gender: this.$t('user_import.Gender'),
        phone_number: this.$t('user_import.Phone'),
        external_id: this.$t('user_import.External_id'),
        user_function: this.$t('user_import.Function'), 
        active: this.$t('common.Active'),
        date_started: this.$t('user_import.Date_started'),
        date_left: this.$t('user_import.Date_left'),
        hours_on_contract: this.$t('user_import.Hours_on_contract'),
        meyer_briggs: this.$t('user_import.Meyer_briggs'),
        disc: this.$t('user_import.Disc'),
        office_based: this.$t('user_import.Office_based'),
        division: this.$t('user_import.Division'),
        language: this.$t('user_import.Language'),
        persona_1: this.$t('user_import.Persona_1'),
        persona_2: this.$t('user_import.Persona_2'),
        persona_3: this.$t('user_import.Persona_3'),
	      work_location: this.$t('user_import.Work_location')
      };
      // Update the fieldsLoaded value
      this.fieldsLoaded = true;
      // Wait 1000ms and set the event listener on the CSV input
      setTimeout(function() {
        let csvSelect = document.querySelector('.csv_select');
        csvSelect.addEventListener('change', e => {
          // Show the validation modal
          this.validationModal = true;
          // Update the isValidating valie
          this.isValidating = true;
          // Disable the submit button
          this.submitButtonDisabled = true;           
          // Reset the csvErrorMessages array
          this.csvErrorMessages = [];
          // Validate the uploaded CSV file
          CSVFileValidator(e.target.files[0], this.csvConfig)
          .then(csvData => {
            // Add the message to the csvErrorMessages array if there is invalid data available
            if(csvData.inValidData && csvData.inValidData.length > 0) {
              for(var i = 0; i < csvData.inValidData.length; i++) {
                this.csvErrorMessages.push(csvData.inValidData[i].message);
              }            
            }
            // Wait 2000ms before resetting the isValidating valie
            setTimeout(function() {
              this.isValidating = false;
            }.bind(this), 2000);
          })
        });
      }.bind(this), 1000);    
    },
    setCsvConfigHeaders() {
      let _this = this;

      this.csvConfig.headers = [
        { 
          name: 'Employee', 
          inputName: 'name', 
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return _this.getRequiredError(headerName, rowNumber, columnNumber);
          }          
        },
        { name: 'Date of birth', inputName: 'dob', required: false },
        { name: 'Gender', inputName: 'gender', required: false },
        { 
          name: 'Email address', 
          inputName: 'email', 
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return _this.getRequiredError(headerName, rowNumber, columnNumber);
          },          
          unique: true,
          uniqueError: function (headerName, rowNumber) {
            return _this.getUniqueError(headerName, rowNumber);
          }, 
          validate: function (email) {
            return _this.isEmailAddressValid(email);
          }, 
          validateError: function (headerName, rowNumber, columnNumber) {
            return _this.getValidateError(headerName, rowNumber, columnNumber);
          }
        },
        { name: 'Phone number', inputName: 'phone_number', required: false },
        { 
          name: 'Employee number', 
          inputName: 'external_id', 
          required: false,
          validate: function (external_id) {
            return _this.isEmployeeNumberUnique(external_id);
          }, 
          validateError: function (headerName, rowNumber, columnNumber) {
            return _this.getUniqueError(headerName, rowNumber);
          }          
        },
        { name: 'Function',  inputName: 'user_function', required: false },
        { name: 'Department', inputName: 'department_name', required: false },
        { name: 'Team', inputName: 'team_name', required: false },
        { name: 'Active', inputName: 'active', required: false },
        { name: 'Date started', inputName: 'date_started', required: false },
        { name: 'Date left', inputName: 'date_left', required: false },
        { name: 'Hours on contract', inputName: 'hours_on_contract', required: false },
        { name: 'Meyer Briggs type', inputName: 'meyer_briggs', required: false },
        { name: 'DISC type', inputName: 'disc', required: false },
        { name: 'Office-Based', inputName: 'office_based', required: false },
        { name: 'Division', inputName: 'division', required: false },
        { name: 'Language', inputName: 'language', required: false },
        { name: 'Persona 1', inputName: 'persona_1', required: false },
        { name: 'Persona 2', inputName: 'persona_2', required: false },
        { name: 'Persona 3', inputName: 'persona_3', required: false },
        { name: 'Work location', inputName: 'work_location', required: false }
      ];  
    },
    clearImportData() {
      // Close the validation modal
      this.validationModal = false;
      // Reset the value of the file input
      document.querySelector('.csv_select').value = null;
      // Clear the csvEmployeeNumbers array
      this.csvEmployeeNumbers = [];      
    },
    loadImportData() {
      this.$refs.vueCSVImport.load();
      // Update the hideMappingButton value
      this.hideMappingButton = true;
      // Enable the submit button
      this.submitButtonDisabled = false;
      // Wait 100ms and clear the import data
      setTimeout(function() {
        this.clearImportData();
      }.bind(this), 100);
    },    
    submitImportData() {
      this.$refs.vueCSVImport.submit();
      // Trigger the correct modal based on the seats_available value
      this.licenseData.seats_available ? this.confirmModal = true : this.maxUserModal = true;
    },
    getRequiredError(headerName, rowNumber, columnNumber) {
      return this.$t('user_import.Required_error', { header_name: headerName, column_number: columnNumber, row_number: rowNumber });
    },
    getUniqueError(headerName, rowNumber) {
      return this.$t('user_import.Unique_error', { header_name: headerName, row_number: rowNumber });
    },
    getValidateError(headerName, rowNumber, columnNumber) {
      return this.$t('user_import.Validate_error', { header_name: headerName, column_number: columnNumber, row_number: rowNumber });      
    },
    isEmailAddressValid(email) {
      const reqExp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/
      return reqExp.test(email);
    },
    isEmployeeNumberUnique(external_id) {
      // Return true if the external_id value is empty
      if(!external_id) return true;
      // Return true if the external_id is not available in the csvEmployeeNumbers array
      if(this.csvEmployeeNumbers.includes(external_id) === false) {
        this.csvEmployeeNumbers.push(external_id);
        return true;
      } else {
        return false;
      }
    },    
    getLicenseData() {
      // Get the license data
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/license/seatsAvailable')
      .then(res => {
        this.licenseData = res.data.data;  
      })
      .catch(err => {
        console.error(err); 
      });
    },
    openLicenseSettings() {
      this.$router.push({path: '/core/license'});
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }
  },
  mounted: function() {
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');  

    this.getPlatformPermissions();
    this.getCompanyData();
    this.getLicenseData();    
  }
}
</script>
