<template>
  <p>{{ $t('howto.Still_having_problems') }} {{ $t('howto.You_can_find') }} <a class="howto_link" @click="openHowTo()"><b>{{subject}}</b></a> {{ $t('howto.on_our_website') }}</p>
</template>

<script>

export default {
  name: 'howToLink',
  props: ['subject', 'url'],
  methods: {
    openHowTo() {
      var win = window.open(this.url, '_blank');
      win.focus();
    }      
  }
}
</script>

<style>
a.howto_link,
a.howto_link:hover {
  color: var(--DashboardPrimary);
}
</style>